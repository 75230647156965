@charset "utf-8";

/*===============================================
 base
===============================================*/
html {
  min-width: $min-width-base-PC;
  font-size: 62.5%;
  display: flex;
  @media #{$sp} {
    min-width: 0;
    font-size: $font-base-sp;
  }
}
body {
  position: relative;
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  min-width: $min-width-base-PC;
  background: $bg-white;
  font-family: $font-sans;
  -webkit-text-size-adjust: 100%;
  @media #{$sp} {
    min-width: $min-width-base-SP;
  }
}
div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ol, ul, li, blockquote, pre, address, fieldset, form, table, caption, tbody, tfoot, thead, tr, th, td, main, article, aside, dialog, figure, figcaption, footer, header, hgroup, menu, nav, section {
  font-size: $font-base-pc;
  font-weight: $font-regular;
  letter-spacing: $letter-spacing-base-pc;
  color: $font-black;
  @media #{$sp} {
    font-size: $font-base-sp;
    letter-spacing: $letter-spacing-base-sp;
  }
}
button {
  background: none;
  border: 0;
  font-family: $font-sans;
  cursor: pointer;
  outline: none;
}
a {
  text-decoration: none;
  color: $link-black;
  word-break: break-all;
  cursor: pointer;
  &:hover {
    color: $link-red;
  }
}
input {
  letter-spacing: $letter-spacing-base-pc;
  color: $font-black;
  @media #{$sp} {
    letter-spacing: $letter-spacing-base-sp;
  }
}
input[type='text'] ,
input[type='password'] {
  padding-right: 15px;
  padding-left: 15px;
  @media #{$sp} {
    padding-right: vw(15);
    padding-left: vw(15);
  }
}
input[type='submit'] ,
input[type='button'] {
  border: 0;
  cursor: pointer;
}
select {
  border-radius: 0;
}
input::placeholder {
  color: $font-gray;
}
button {
  margin: 0;
  padding: 0;
  letter-spacing: $letter-spacing-base-pc;
  color: $font-black;
  @media #{$sp} {
    letter-spacing: $letter-spacing-base-sp;
  }
}
textarea {
  font-family: $font-sans;
  resize: vertical;
}
*::before, *::after {
  display: inline-block;
  @media #{$sp} {
    font-size: calc(24vw / 750 * 100);
  }
}

/*===============================================
 pc sp setting
===============================================*/
.inline-sp {
  display: none !important;
  @media #{$sp} {
    display: inline !important;
  }
}
.inlineBlock-sp {
  display: none !important;
  @media #{$sp} {
    display: inline-block !important;
  }
}
.block-sp {
  display: none !important;
  @media #{$sp} {
    display: block !important;
  }
}
.img-sp {
  display: none !important;
  @media #{$sp} {
    display: inline !important;
  }
}
.inline-pc {
  display: inline !important;
  @media #{$sp} {
    display: none !important;
  }
}
.inlineBlock-pc {
  display: inline-block !important;
  @media #{$sp} {
    display: none !important;
  }
}
.block-pc {
  display: block !important;
  @media #{$sp} {
    display: none !important;
  }
}

/*-----------------------------------------------
 style class 
-----------------------------------------------*/

/*...............................................
 float
...............................................*/
/*
float right
*/
.fr {
  float: right !important;
}
@media #{$pc} {
  .fr {
    &-pc {
      @media #{$pc} {
        float: right !important;
      }
    }
  }
}
@media #{$sp} {
  .fr {
    &-sp {
      @media #{$sp} {
        float: right !important;
      }
    }
  }
}
/*
float left
*/
.fl {
  float: left !important;
  &-pc {
    @media #{$pc} {
      float: left !important;
    }
  }
  &-sp {
    @media #{$sp} {
      float: left !important;
    }
  }
}
@media #{$pc} {
  .fl {
    &-pc {
      @media #{$pc} {
        float: left !important;
      }
    }
  }
}
@media #{$sp} {
  .fl {
    &-sp {
      @media #{$sp} {
        float: left !important;
      }
    }
  }
}
/*...............................................
 margin
...............................................*/
/*
 margin-top
*/
@media #{$pc} {
  .mt- {
    &pc-{
      &5   {margin-top:   5px !important;}
      &10  {margin-top:  10px !important;}
      &15  {margin-top:  15px !important;}
      &20  {margin-top:  20px !important;}
      &25  {margin-top:  25px !important;}
      &30  {margin-top:  30px !important;}
      &35  {margin-top:  35px !important;}
      &40  {margin-top:  40px !important;}
      &45  {margin-top:  45px !important;}
      &50  {margin-top:  50px !important;}
      &55  {margin-top:  55px !important;}
      &60  {margin-top:  60px !important;}
      &65  {margin-top:  65px !important;}
      &70  {margin-top:  70px !important;}
      &75  {margin-top:  75px !important;}
      &80  {margin-top:  80px !important;}
      &85  {margin-top:  85px !important;}
      &90  {margin-top:  90px !important;}
      &95  {margin-top:  95px !important;}
      &100 {margin-top: 100px !important;}
    }
  }
}
@media #{$sp} {
  .mt- {
    &sp-{
      &5   {margin-top:   vw(5) !important;}
      &10  {margin-top:  vw(10) !important;}
      &15  {margin-top:  vw(15) !important;}
      &20  {margin-top:  vw(20) !important;}
      &25  {margin-top:  vw(25) !important;}
      &30  {margin-top:  vw(30) !important;}
      &35  {margin-top:  vw(35) !important;}
      &40  {margin-top:  vw(40) !important;}
      &45  {margin-top:  vw(45) !important;}
      &50  {margin-top:  vw(50) !important;}
      &55  {margin-top:  vw(55) !important;}
      &60  {margin-top:  vw(60) !important;}
      &65  {margin-top:  vw(65) !important;}
      &70  {margin-top:  vw(70) !important;}
      &75  {margin-top:  vw(75) !important;}
      &80  {margin-top:  vw(80) !important;}
      &85  {margin-top:  vw(85) !important;}
      &90  {margin-top:  vw(90) !important;}
      &95  {margin-top:  vw(95) !important;}
      &100 {margin-top: vw(100) !important;}
    }
  }
}
/*
 margin-right
*/
@media #{$pc} {
  .mr- {
    &pc- {
      &5   {margin-right:   5px !important;}
      &10  {margin-right:  10px !important;}
      &15  {margin-right:  15px !important;}
      &20  {margin-right:  20px !important;}
      &25  {margin-right:  25px !important;}
      &30  {margin-right:  30px !important;}
      &35  {margin-right:  35px !important;}
      &40  {margin-right:  40px !important;}
      &45  {margin-right:  45px !important;}
      &50  {margin-right:  50px !important;}
      &55  {margin-right:  55px !important;}
      &60  {margin-right:  60px !important;}
      &65  {margin-right:  65px !important;}
      &70  {margin-right:  70px !important;}
      &75  {margin-right:  75px !important;}
      &80  {margin-right:  80px !important;}
      &85  {margin-right:  85px !important;}
      &90  {margin-right:  90px !important;}
      &95  {margin-right:  95px !important;}
      &100 {margin-right: 100px !important;}
    }
  }
}
@media #{$sp} {
  .mr- {
    &sp- {
      &5   {margin-right:   vw(5) !important;}
      &10  {margin-right:  vw(10) !important;}
      &15  {margin-right:  vw(15) !important;}
      &20  {margin-right:  vw(20) !important;}
      &25  {margin-right:  vw(25) !important;}
      &30  {margin-right:  vw(30) !important;}
      &35  {margin-right:  vw(35) !important;}
      &40  {margin-right:  vw(40) !important;}
      &45  {margin-right:  vw(45) !important;}
      &50  {margin-right:  vw(50) !important;}
      &55  {margin-right:  vw(55) !important;}
      &60  {margin-right:  vw(60) !important;}
      &65  {margin-right:  vw(65) !important;}
      &70  {margin-right:  vw(70) !important;}
      &75  {margin-right:  vw(75) !important;}
      &80  {margin-right:  vw(80) !important;}
      &85  {margin-right:  vw(85) !important;}
      &90  {margin-right:  vw(90) !important;}
      &95  {margin-right:  vw(95) !important;}
      &100 {margin-right: vw(100) !important;}
    }
  }
}
/*
 margin-bottom
*/
@media #{$pc} {
  .mb- {
    &pc- {
      &5   {margin-bottom:   5px !important;}
      &10  {margin-bottom:  10px !important;}
      &15  {margin-bottom:  15px !important;}
      &20  {margin-bottom:  20px !important;}
      &25  {margin-bottom:  25px !important;}
      &30  {margin-bottom:  30px !important;}
      &35  {margin-bottom:  35px !important;}
      &40  {margin-bottom:  40px !important;}
      &45  {margin-bottom:  45px !important;}
      &50  {margin-bottom:  50px !important;}
      &55  {margin-bottom:  55px !important;}
      &60  {margin-bottom:  60px !important;}
      &65  {margin-bottom:  65px !important;}
      &70  {margin-bottom:  70px !important;}
      &75  {margin-bottom:  75px !important;}
      &80  {margin-bottom:  80px !important;}
      &85  {margin-bottom:  85px !important;}
      &90  {margin-bottom:  90px !important;}
      &95  {margin-bottom:  95px !important;}
      &100 {margin-bottom: 100px !important;}
    }
  }
}
@media #{$sp} {
  .mb- {
    &sp- {
      &5   {margin-bottom:   5px !important;}
      &10  {margin-bottom:  vw(10) !important;}
      &15  {margin-bottom:  vw(15) !important;}
      &20  {margin-bottom:  vw(20) !important;}
      &25  {margin-bottom:  vw(25) !important;}
      &30  {margin-bottom:  vw(30) !important;}
      &35  {margin-bottom:  vw(35) !important;}
      &40  {margin-bottom:  vw(40) !important;}
      &45  {margin-bottom:  vw(45) !important;}
      &50  {margin-bottom:  vw(50) !important;}
      &55  {margin-bottom:  vw(55) !important;}
      &60  {margin-bottom:  vw(60) !important;}
      &65  {margin-bottom:  vw(65) !important;}
      &70  {margin-bottom:  vw(70) !important;}
      &75  {margin-bottom:  vw(75) !important;}
      &80  {margin-bottom:  vw(80) !important;}
      &85  {margin-bottom:  vw(85) !important;}
      &90  {margin-bottom:  vw(90) !important;}
      &95  {margin-bottom:  vw(95) !important;}
      &100 {margin-bottom: vw(100) !important;}
    }
  }
}
/*
 margin-left
*/
@media #{$pc} {
  .ml- {
    &pc- {
      &5   {margin-left:   5px !important;}
      &10  {margin-left:  10px !important;}
      &15  {margin-left:  15px !important;}
      &20  {margin-left:  20px !important;}
      &25  {margin-left:  25px !important;}
      &30  {margin-left:  30px !important;}
      &35  {margin-left:  35px !important;}
      &40  {margin-left:  40px !important;}
      &45  {margin-left:  45px !important;}
      &50  {margin-left:  50px !important;}
      &55  {margin-left:  55px !important;}
      &60  {margin-left:  60px !important;}
      &65  {margin-left:  65px !important;}
      &70  {margin-left:  70px !important;}
      &75  {margin-left:  75px !important;}
      &80  {margin-left:  80px !important;}
      &85  {margin-left:  85px !important;}
      &90  {margin-left:  90px !important;}
      &95  {margin-left:  95px !important;}
      &100 {margin-left: 100px !important;}
    }
  }
}
@media #{$sp} {
  .ml- {
    &sp- {
      &5   {margin-left:   vw(5) !important;}
      &10  {margin-left:  vw(10) !important;}
      &15  {margin-left:  vw(15) !important;}
      &20  {margin-left:  vw(20) !important;}
      &25  {margin-left:  vw(25) !important;}
      &30  {margin-left:  vw(30) !important;}
      &35  {margin-left:  vw(35) !important;}
      &40  {margin-left:  vw(40) !important;}
      &45  {margin-left:  vw(45) !important;}
      &50  {margin-left:  vw(50) !important;}
      &55  {margin-left:  vw(55) !important;}
      &60  {margin-left:  vw(60) !important;}
      &65  {margin-left:  vw(65) !important;}
      &70  {margin-left:  vw(70) !important;}
      &75  {margin-left:  vw(75) !important;}
      &80  {margin-left:  vw(80) !important;}
      &85  {margin-left:  vw(85) !important;}
      &90  {margin-left:  vw(90) !important;}
      &95  {margin-left:  vw(95) !important;}
      &100 {margin-left: vw(100) !important;}
    }
  }
}

/*...............................................
 padding
...............................................*/
/*
 padding-top
*/
@media #{$pc} {
  .pt- {
    &pc- {
      &5   {padding-top:   5px !important;}
      &10  {padding-top:  10px !important;}
      &15  {padding-top:  15px !important;}
      &20  {padding-top:  20px !important;}
      &25  {padding-top:  25px !important;}
      &30  {padding-top:  30px !important;}
      &35  {padding-top:  35px !important;}
      &40  {padding-top:  40px !important;}
      &45  {padding-top:  45px !important;}
      &50  {padding-top:  50px !important;}
      &55  {padding-top:  55px !important;}
      &60  {padding-top:  60px !important;}
      &65  {padding-top:  65px !important;}
      &70  {padding-top:  70px !important;}
      &75  {padding-top:  75px !important;}
      &80  {padding-top:  80px !important;}
      &85  {padding-top:  85px !important;}
      &90  {padding-top:  90px !important;}
      &95  {padding-top:  95px !important;}
      &100 {padding-top: 100px !important;}
    }
  }
}
@media #{$sp} {
  .pt- {
    &sp- {
      &5   {padding-top:   vw(5) !important;}
      &10  {padding-top:  vw(10) !important;}
      &15  {padding-top:  vw(15) !important;}
      &20  {padding-top:  vw(20) !important;}
      &25  {padding-top:  vw(25) !important;}
      &30  {padding-top:  vw(30) !important;}
      &35  {padding-top:  vw(35) !important;}
      &40  {padding-top:  vw(40) !important;}
      &45  {padding-top:  vw(45) !important;}
      &50  {padding-top:  vw(50) !important;}
      &55  {padding-top:  vw(55) !important;}
      &60  {padding-top:  vw(60) !important;}
      &65  {padding-top:  vw(65) !important;}
      &70  {padding-top:  vw(70) !important;}
      &75  {padding-top:  vw(75) !important;}
      &80  {padding-top:  vw(80) !important;}
      &85  {padding-top:  vw(85) !important;}
      &90  {padding-top:  vw(90) !important;}
      &95  {padding-top:  vw(95) !important;}
      &100 {padding-top: vw(100) !important;}
    }
  }
}

/*
 padding-right
*/
@media #{$pc} {
  .pr- {
    &pc- {
      &5   {padding-right:   5px !important;}
      &10  {padding-right:  10px !important;}
      &15  {padding-right:  15px !important;}
      &20  {padding-right:  20px !important;}
      &25  {padding-right:  25px !important;}
      &30  {padding-right:  30px !important;}
      &35  {padding-right:  35px !important;}
      &40  {padding-right:  40px !important;}
      &45  {padding-right:  45px !important;}
      &50  {padding-right:  50px !important;}
      &55  {padding-right:  55px !important;}
      &60  {padding-right:  60px !important;}
      &65  {padding-right:  65px !important;}
      &70  {padding-right:  70px !important;}
      &75  {padding-right:  75px !important;}
      &80  {padding-right:  80px !important;}
      &85  {padding-right:  85px !important;}
      &90  {padding-right:  90px !important;}
      &95  {padding-right:  95px !important;}
      &100 {padding-right: 100px !important;}
    }
  }
}
@media #{$sp} {
  .pr- {
    &sp- {
      &5   {padding-right:   vw(5) !important;}
      &10  {padding-right:  vw(10) !important;}
      &15  {padding-right:  vw(15) !important;}
      &20  {padding-right:  vw(20) !important;}
      &25  {padding-right:  vw(25) !important;}
      &30  {padding-right:  vw(30) !important;}
      &35  {padding-right:  vw(35) !important;}
      &40  {padding-right:  vw(40) !important;}
      &45  {padding-right:  vw(45) !important;}
      &50  {padding-right:  vw(50) !important;}
      &55  {padding-right:  vw(55) !important;}
      &60  {padding-right:  vw(60) !important;}
      &65  {padding-right:  vw(65) !important;}
      &70  {padding-right:  vw(70) !important;}
      &75  {padding-right:  vw(75) !important;}
      &80  {padding-right:  vw(80) !important;}
      &85  {padding-right:  vw(85) !important;}
      &90  {padding-right:  vw(90) !important;}
      &95  {padding-right:  vw(95) !important;}
      &100 {padding-right: vw(100) !important;}
    }
  }
}
/*
 padding-bottom
*/
@media #{$pc} {
  .pb- {
    &pc- {
      &5   {padding-bottom:   5px !important;}
      &10  {padding-bottom:  10px !important;}
      &15  {padding-bottom:  15px !important;}
      &20  {padding-bottom:  20px !important;}
      &25  {padding-bottom:  25px !important;}
      &30  {padding-bottom:  30px !important;}
      &35  {padding-bottom:  35px !important;}
      &40  {padding-bottom:  40px !important;}
      &45  {padding-bottom:  45px !important;}
      &50  {padding-bottom:  50px !important;}
      &55  {padding-bottom:  55px !important;}
      &60  {padding-bottom:  60px !important;}
      &65  {padding-bottom:  65px !important;}
      &70  {padding-bottom:  70px !important;}
      &75  {padding-bottom:  75px !important;}
      &80  {padding-bottom:  80px !important;}
      &85  {padding-bottom:  85px !important;}
      &90  {padding-bottom:  90px !important;}
      &95  {padding-bottom:  95px !important;}
      &100 {padding-bottom: 100px !important;}
    }
  }
}
@media #{$sp} {
  .pb- {
    &sp- {
      &5   {padding-bottom:   vw(5) !important;}
      &10  {padding-bottom:  vw(10) !important;}
      &15  {padding-bottom:  vw(15) !important;}
      &20  {padding-bottom:  vw(20) !important;}
      &25  {padding-bottom:  vw(25) !important;}
      &30  {padding-bottom:  vw(30) !important;}
      &35  {padding-bottom:  vw(35) !important;}
      &40  {padding-bottom:  vw(40) !important;}
      &45  {padding-bottom:  vw(45) !important;}
      &50  {padding-bottom:  vw(50) !important;}
      &55  {padding-bottom:  vw(55) !important;}
      &60  {padding-bottom:  vw(60) !important;}
      &65  {padding-bottom:  vw(65) !important;}
      &70  {padding-bottom:  vw(70) !important;}
      &75  {padding-bottom:  vw(75) !important;}
      &80  {padding-bottom:  vw(80) !important;}
      &85  {padding-bottom:  vw(85) !important;}
      &90  {padding-bottom:  vw(90) !important;}
      &95  {padding-bottom:  vw(95) !important;}
      &100 {padding-bottom: vw(100) !important;}
    }
  }
}
/*
 padding-left
*/
@media #{$pc} {
  .pl- {
    &pc- {
      &5   {padding-left:   5px !important;}
      &10  {padding-left:  10px !important;}
      &15  {padding-left:  15px !important;}
      &20  {padding-left:  20px !important;}
      &25  {padding-left:  25px !important;}
      &30  {padding-left:  30px !important;}
      &35  {padding-left:  35px !important;}
      &40  {padding-left:  40px !important;}
      &45  {padding-left:  45px !important;}
      &50  {padding-left:  50px !important;}
      &55  {padding-left:  55px !important;}
      &60  {padding-left:  60px !important;}
      &65  {padding-left:  65px !important;}
      &70  {padding-left:  70px !important;}
      &75  {padding-left:  75px !important;}
      &80  {padding-left:  80px !important;}
      &85  {padding-left:  85px !important;}
      &90  {padding-left:  90px !important;}
      &95  {padding-left:  95px !important;}
      &100 {padding-left: 100px !important;}
    }
  }
}
@media #{$sp} {
  .pl- {
    &sp- {
      &5   {padding-left:   vw(5) !important;}
      &10  {padding-left:  vw(10) !important;}
      &15  {padding-left:  vw(15) !important;}
      &20  {padding-left:  vw(20) !important;}
      &25  {padding-left:  vw(25) !important;}
      &30  {padding-left:  vw(30) !important;}
      &35  {padding-left:  vw(35) !important;}
      &40  {padding-left:  vw(40) !important;}
      &45  {padding-left:  vw(45) !important;}
      &50  {padding-left:  vw(50) !important;}
      &55  {padding-left:  vw(55) !important;}
      &60  {padding-left:  vw(60) !important;}
      &65  {padding-left:  vw(65) !important;}
      &70  {padding-left:  vw(70) !important;}
      &75  {padding-left:  vw(75) !important;}
      &80  {padding-left:  vw(80) !important;}
      &85  {padding-left:  vw(85) !important;}
      &90  {padding-left:  vw(90) !important;}
      &95  {padding-left:  vw(95) !important;}
      &100 {padding-left: vw(100) !important;}
    }
  }
}
/**
 * jQuery Line Progressbar
 * Author: KingRayhan<rayhan095@gmail.com>
 * Author URL: https://electronthemes.com
 * Version: 1.1.1
 */


//-----------------------------------------------
// foundation
//-----------------------------------------------
@import '../../../../import/foundation/config';
@import '../../../../import/foundation/reset';
@import '../../../../import/foundation/base';
@import '../../../../import/foundation/tools';

/** Progressbar class css*/
.progressbar {
  width: 100%;
  position: relative;
  // background-color: #EEEEEE;
  // box-shadow: inset 0px 1px 1px rgba(0,0,0,.1);
}

.proggress{
  /* width: 10px; */
  width: 0;
  height: 3px !important;
  /* background-color: #3498db; */
  background-color: $bg-brown !important;
}
// .proggress {
//   /* width: 0; */
// }

// .percentCount{
//   float:right;
//   margin-top: 10px;
//   clear: both;
//   font-weight: bold;
//   font-family: Arial
// }

#js-progressbar {
  width: 100%;
}
#js-progress-box {
  width: 100%;
  height: 60px;
  background: #c5c5c5;
  position: relative;
}
#js-progress-bar {
  width: 0;
  height: 60px;
  background: #00cab9;
  position: absolute;
  left: 0;
  top: 0;
}


@charset "utf-8";

//===============================================
// config
//===============================================
$domain: '';
$root: '/';
$path: $domain + $root;
$img: $path + 'assets/images/common/';
$img-top: $path + 'assets/images/top/';
$img-profile: $path + 'assets/images/profile/';
$img-signup: $path + 'assets/images/signup/';
$img-teamprofile: $path + 'assets/images/teamprofile/';
$img-search: $path + 'assets/images/search/';
$img-company: $path + 'assets/images/company/';
$img-about: $path + 'assets/images/top/';
$img-info: $path + 'assets/images/info/';
$img-teamlist: $path + 'assets/images/teamlist/';

$min-width-base-PC: 1050px;
$min-width-base-SP: auto;

$width-sp:   750;
$percent-sp: 100;

$pc: 'only screen and (min-width: 768px)';
$sp: 'only screen and (max-width: 767px)';
$ie: 'all and (-ms-high-contrast:none)';

$bg-white:      #fff;
$bg-whiteGray:  #fafafa;
$bg-black:      #202020;
$bg-lightGray:  #eee;
$bg-gray:       #bdbdbd;
$bg-darkGray:   #555;
$bg-blueGray:   #ebf3f7;
$bg-brown:      #b08c36;
// $bg-blue:       #2944cc;
$bg-blue:       #21b5d8;
$bg-facebook:   #10409b;
$bg-skyblue:    #00abd2;
$bg-orange:     #fa4c00;
$bg-tab:        #f4f6fa;
$bg-tabHover:   #e0e6f1;
//$bg-red:        #c10000;
$bg-red:        #dc2724;
$bg-green:      #1ebe56;
$bg-lightblue:  #21b5d8;

$border-black:     #202020;
$border-darkBlack: #202020;
$border-lightGray: #e0e0e0;
$border-gray:      #eee;
$border-darkGray:  #bdbdbd;
$border-white:     #fff;
//$border-red:       #c10000;
$border-red:       #dc2724;
$border-lightblue: #21b5d8;
$border-blue:      #2944cc;
$border-green:     #1ebe56;
$border-error:     #e60012;
$border-brown:     #b08c36;

$font-black:    #202020;
$font-white:    #fff;
$font-gray:     #bdbdbd;
$font-darkGray: #757575;
$font-darkGray02: #515051;
$font-lightRed: #ff0000;
//$font-red:      #c10000;
$font-red:      #dc2724;
$font-darkRed:  #c10000;
$font-brown:    #b08c36;
$font-error:    #e60012;

$font-green:    #62bf66;
$font-titBlue:  #21b5d8;
$font-titGreen: #1ebe56;

$link-black:    #202020;
$link-brown:    #b08c36;
//$link-red:      #c10000;
$link-red:      #dc2724;


$font-sans: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Noto Sans JP", -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "Droid Sans", "Helvetica Neue", sans-serif;
$font-serif: "Noto Serif JP", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝体", "Yu Mincho", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;

$font-base-pc: 1.2rem;
$font-base-sp: calc(24vw / 750 * 100);

$font-xxs-pc: 1rem;
$font-xs-pc:  1.1rem;
$font-s-pc:   1.2rem;
$font-m-pc:   1.3rem;
$font-l-pc:   1.4rem;
$font-xl-pc:  1.5rem;
$font-xxl-pc: 1.6rem;

$font-xxs-sp: calc(20vw / #{$width-sp} * #{$percent-sp});
$font-xs-sp:  calc(22vw / #{$width-sp} * #{$percent-sp});
$font-s-sp:   calc(24vw / #{$width-sp} * #{$percent-sp});
$font-m-sp:   calc(26vw / #{$width-sp} * #{$percent-sp});
$font-l-sp:   calc(28vw / #{$width-sp} * #{$percent-sp});
$font-xl-sp:  calc(30vw / #{$width-sp} * #{$percent-sp});
$font-xxl-sp: calc(32vw / #{$width-sp} * #{$percent-sp});

$font-thin:      100;
$font-light:     200;
$font-demilight: 300;
$font-regular:   400;
$font-medium:    500;
$font-semibold:  600;
$font-bold:      700;
$font-heavy:     900;

$line-height-base-pc: (24 / 12);
$line-height-xxs-pc:  (20 / 10);
$line-height-xs-pc:   (22 / 11);
$line-height-s-pc:    (24 / 12);
$line-height-m-pc:    (26 / 13);
$line-height-l-pc:    (26 / 14);
$line-height-xl-pc:   (30 / 15);
$line-height-xxl-pc:  (32 / 16);

$line-height-base-sp: (40 / 24);
$line-height-xxs-sp:  (40 / 20);
$line-height-xs-sp:   (40 / 22);
$line-height-s-sp:    (40 / 24);
$line-height-m-sp:    (40 / 26);
$line-height-l-sp:    (40 / 28);
$line-height-xl-sp:   (40 / 30);
$line-height-xxl-sp:  (40 / 32);

$letter-spacing-base-pc: 0.05em;
$letter-spacing-base-sp: 0.05em;

$vw:  0;
$rem: 0;

//-----------------------------------------------
// vw
//-----------------------------------------------
@function vw($value) {
  @return calc(#{$value}vw / #{$width-sp} * #{$percent-sp});
}

//-----------------------------------------------
// font-family
//-----------------------------------------------
@mixin ff($type) {

  @if $type == serif {
    font-family: $font-serif;
  } @else if $type == sans {
    font-family: $font-sans;
  }

}

//-----------------------------------------------
// font-size
//-----------------------------------------------
@mixin font-size($device, $size) {
  @if $device == pc {
    @if $size == xxs {
      font-size: $font-xxs-pc;
    } @else if $size == xs {
      font-size: $font-xs-pc;
    } @else if $size == s {
      font-size: $font-s-pc;
    } @else if $size == m {
      font-size: $font-m-pc;
    } @else if $size == l {
      font-size: $font-l-pc;
    } @else if $size == xl {
      font-size: $font-xl-pc;
    } @else if $size == xxl {
      font-size: $font-xxl-pc;
    } @else {
      font-size: $size / 10 * 1rem;
    }
  } @else if $device == sp {
    @if $size == xxs {
      font-size: $font-xxs-sp;
    } @else if $size == xs {
      font-size: $font-xs-sp;
    } @else if $size == s {
      font-size: $font-s-sp;
    } @else if $size == m {
      font-size: $font-m-sp;
    } @else if $size == l {
      font-size: $font-l-sp;
    } @else if $size == xl {
      font-size: $font-xl-sp;
    } @else if $size == xxl {
      font-size: $font-xxl-sp;
    } @else {
      font-size: calc(#{$size}vw / #{$width-sp} * #{$percent-sp});
    }
  }

}

//-----------------------------------------------
// font-weight
//-----------------------------------------------
@mixin font-weight($weight) {

  @if $weight == thin {
    font-weight: $font-thin;
  } @else if $weight == light {
    font-weight: $font-light;
  } @else if $weight == demilight {
    font-weight: $font-demilight;
  } @else if $weight == regular {
    font-weight: $font-regular;
  } @else if $weight == medium {
    font-weight: $font-medium;
  } @else if $weight == semibold {
    font-weight: $font-semibold;
  } @else if $weight == bold {
    font-weight: $font-bold;
  } @else if $weight == heavy {
    font-weight: $font-heavy;
  }

}

//-----------------------------------------------
// line-height
//-----------------------------------------------
@mixin line-height($device, $type) {

  @if $device == pc {
    @if $type == base {
      line-height: $line-height-base-pc;
    } @else if $type == xxs {
      line-height: $line-height-xxs-pc;
    } @else if $type == xs {
      line-height: $line-height-xs-pc;
    } @else if $type == s {
      line-height: $line-height-s-pc;
    } @else if $type == m {
      line-height: $line-height-m-pc;
    } @else if $type == l {
      line-height: $line-height-l-pc;
    } @else if $type == xl {
      line-height: $line-height-xl-pc;
    } @else if $type == xxl {
      line-height: $line-height-xxl-pc;
    } @else {
      line-height: ($type);
    }
  } @else if $device == sp {
    @if $type == base {
      line-height: $line-height-base-sp;
    } @else if $type == xxs {
      line-height: $line-height-xxs-sp;
    } @else if $type == xs {
      line-height: $line-height-xs-sp;
    } @else if $type == s {
      line-height: $line-height-s-sp;
    } @else if $type == m {
      line-height: $line-height-m-sp;
    } @else if $type == l {
      line-height: $line-height-l-sp;
    } @else if $type == xl {
      line-height: $line-height-xl-sp;
    } @else if $type == xxl {
      line-height: $line-height-xxl-sp;
    } @else {
      line-height: ($type);
    }
  }

}

//-----------------------------------------------
// btnTransition
//-----------------------------------------------
@mixin btnTransition {
  transition: all 0.3s;
  &:hover {
    letter-spacing: 4px;
    @media #{$sp} {
      letter-spacing: vw(8);
    }
  }
}

//-----------------------------------------------
// hover
//-----------------------------------------------
@mixin hover( $opacity:.8 ) {

    $ie: $opacity * 100;

    transition: opacity .3s linear;
    opacity: 1;

    &:hover {
        opacity: $opacity;
        -ms-filter: "alpha(opacity=#{$ie})";
    }
}

//-----------------------------------------------
// pos
//-----------------------------------------------
@mixin pos( $pos_type, $pos_point, $pos_h, $pos_v ) {
    position: $pos_type;

    @if $pos_point == "lt" {
        left: $pos_h;
        top: $pos_v;
    }
    @if $pos_point == "lb" {
        left: $pos_h;
        bottom: $pos_v;
    }
    @if $pos_point == "rt" {
        right: $pos_h;
        top: $pos_v;
    }
    @if $pos_point == "rb" {
        right: $pos_h;
        bottom: $pos_v;
    }
}

//-----------------------------------------------
// block
//-----------------------------------------------
@mixin block( $width, $height, $inline:false ) {
    @if $inline == false {
        display: block;
    }
    @if $inline == true {
        display: inline-block;
    }
    width: $width;
    height: $height;
}

//-----------------------------------------------
// liquid element
//-----------------------------------------------
@mixin liquid( $width, $height, $parent_width, $parent_height:null ) {
    display: block;

    @if ( unitless($width) ) {
        width: 100% * ( $width / $parent_width );
    }
    @else {
        width: $width;
    }

    @if ( unitless($height) ) {
        @if ( $parent_height ) {
            height: 100% * ( $height / $parent_height );
        }
        @else {
            height: 0;
            padding-top: 100% * ( $height / $parent_width );
        }
    }
    @else {
        height: 0;
        padding-top: $height;
    }
}

//-----------------------------------------------
// img
//-----------------------------------------------
@mixin img( $url ) {
    background: url( "#{$url}" ) no-repeat;
    background-size: 100% 100%;

    span, strong {
        display: none;
    }
}

//-----------------------------------------------
// width
//-----------------------------------------------
@mixin widthPercent( $parentPxWidth, $pxWidth ){
    width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

